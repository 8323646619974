.box-gray__content{
  text-align: center;
  font-size: rem-calc(14px);

  .--text{
    color: $dark-gray;
    padding-right: rem-calc(10px);
    font-weight: bold;
  }

  .--pipe{
    color: $dark-gray;
    padding: rem-calc(0 10px);
  }

  .--link{
    color: $primary;
  }
}
