.select-contract{

  .select-contract__list{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .select-contract__list > * + * {
    margin-top: rem-calc(5px);
  }

  .select-contract__item{
    width: 100%;
    background-color: $light-gray;
  }

  .item__text{
    font-size: rem-calc(14px);
    color: $secondary;
    padding-left: rem-calc(30px);
  }

  .item__button{
    text-align: right;
    width: 135px;

    .button{
      margin: 0;
      text-transform: uppercase;
    }
  }
}
