.approve-timesheets{

  .approve-timesheets__table{
    font-size: rem-calc(10px);

    .table__head{
    }

    .table__head-row{
      th{
        text-align: center;
      }
    }

    .table__body{
    }

    .table__body-row{
      td{
        text-align: center;

        a{
          color: $primary;
          text-decoration: underline;
        }

        .table_checkbox{
          margin: 1px;
        }
      }

      .approval-waiting{
        color: $approval-waiting;
      }

      .approval-approved{
        color: $approval-approved;
      }

      .approval-declined{
        color: $approval-declined;
      }
    }
  }

  .table-button{
    text-transform: uppercase;
    margin-bottom: 0;
  }

}
