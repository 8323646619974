.add-amend-entries{
  .calendar {
    margin: rem-calc(0 0 20px 0);
    * {
      list-style: none;
      line-height: 1;
    }
    .title {
      text-align: center;
      font-size: 1.6875rem;
    }
    .week {
      padding: 0;
      margin: 0;
      .day, .days {
        outline: 1px solid $light-gray;
      }
      &:after, &:before {
        content: " ";
        display: table;
      }
      &:after {
        clear: both;
      }
    }
    .day, .days {
      position: relative;
      padding: 0.3rem 0.39375rem;
      width: 100%;
      min-height: 6rem;
      float: left;
    }
    .day-header {
      font-family: $body-font-family-medium;
      font-size: rem-calc(10px);
      text-transform: uppercase;
      .day, .days {
        color: $dark-gray;
        text-align: center;
        min-height: 0.5rem;
        display: none;
        padding-bottom: rem-calc(10px);
      }
    }
  }

  .day {
    &.next-month, &.previous-month {
      color: $light-gray;
      display: none;
    }
  }

  .days {
    &.next-month, &.previous-month {
      color: $light-gray;
      display: none;
    }
  }

  .day, .days{
    .day__content{
      display: table;
      width: 100%;
    }
    .day__content-row{
      display: table-row;
    }
    .day__content-cell{
      display: table-cell;

      @include breakpoint(small down){
        display: block;
        width: 100%;
        min-height: 20px;
      }

      &.status{
        &.approved{
          background-color: $approval-approved;
        }
        &.awaiting{
          background-color: $approval-waiting;
        }
        &.declined{
          background-color: $approval-declined;
        }
      }
      &.date{
        text-align: right;
        color: $dark-gray;

        @include breakpoint(small down){
          text-align: left;
        }
      }
      & label{
        font-size: rem-calc(9px);
        color: $dark-gray;
        padding-top: 20px;
        margin-right: 1px;
      }
      & select{
        margin: 0;
        height: auto;
        font-size: 10px;
        padding: 5px 2px 5px 2px;
      }
    }
  }

  .add-amend-entries-btn{
    text-transform: uppercase;
    font-family: $body-font-family-bold;
    font-size: rem-calc(12px);

    @include breakpoint(small down){
      width: 100%;
    }
  }

  @media only screen {
    .calendar {
      .small-1 {
        position: relative;
        width: 14.28571%;
      }
      .day-header .small-1 {
        display: block;
        visibility: visible;
      }
    }
    .small-1 {
      &.next-month, &.previous-month {
        display: block;
        visibility: visible;
      }
    }
  }

  @media only screen and (min-width: 40.063em) {
    .calendar {
      .medium-1 {
        position: relative;
        width: 14.28571%;
      }
      .day-header .medium-1 {
        display: block;
        visibility: visible;
      }
    }
    .medium-1 {
      &.next-month, &.previous-month {
        display: block;
        visibility: visible;
      }
    }
  }

  @media only screen and (min-width: 64.063em) {
    .calendar {
      .large-1 {
        position: relative;
        width: 14.28571%;
      }
      .day-header .large-1 {
        display: block;
        visibility: visible;
      }
    }
    .large-1 {
      &.next-month, &.previous-month {
        display: block;
        visibility: visible;
      }
    }
  }

  @media only screen and (min-width: 90.063em) {
    .calendar {
      .xlarge-1 {
        position: relative;
        width: 14.28571%;
      }
      .day-header .xlarge-1 {
        display: block;
        visibility: visible;
      }
    }
    .xlarge-1 {
      &.next-month, &.previous-month {
        display: block;
        visibility: visible;
      }
    }
  }

  @media only screen and (min-width: 120.063em) {
    .calendar {
      .xxlarge-1 {
        position: relative;
        width: 14.28571%;
      }
      .day-header .xxlarge-1 {
        display: block;
        visibility: visible;
      }
    }
    .xxlarge-1 {
      &.next-month, &.previous-month {
        display: block;
        visibility: visible;
      }
    }
  }
}

.timesheet-details{
  display: table;
  width: 70%;

  @include breakpoint(small down){
    width: 100%;
  }

  .timesheet-details__title{
    font-family: $body-font-family-medium;
    font-size: rem-calc(12px);
    text-transform: uppercase;
    color: $dark-gray;
    margin-bottom: rem-calc(15px);
  }

  .timesheet-details__row{
    display: table-row;
  }

  span{
    display: table-cell;
    font-size: rem-calc(12px);
    color: $dark-gray;
    width: 50%;

    &.right{
      text-align: right;
      color: $primary;
    }

  }
}

.timesheet-key{
  border-left: 1px solid $medium-gray;

  @include breakpoint(small down){
    border-left: none;
  }

  .timesheet-key__title{
    font-family: $body-font-family-medium;
    font-size: rem-calc(12px);
    text-transform: uppercase;
    color: $dark-gray;
    margin-bottom: rem-calc(15px);

    @include breakpoint(small down){
      margin-top: rem-calc(15px);
    }
  }

  .timesheet-key__box{
    display: table;
    padding-left: 50px;

    @include breakpoint(small down){
      padding-left: 0;
    }
  }

  .timesheet-key__row{
    display: table-row;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .key, .text{
      display: table-cell;
      font-size: rem-calc(12px);
      color: $dark-gray;
      width: 50%;
    }

    .key{
      &.approved{
        background-color: $approval-approved;
      }
      &.awaiting{
        background-color: $approval-waiting;
      }
      &.declined{
        background-color: $approval-declined;
      }
    }
  }

  .timesheet-key__spacer{
    height: 10px;
  }
}

.save-cancel-modal{
  padding: 40px 80px;
  color: $dark-gray;

  @include breakpoint(small down){
    padding: 40px 10px;
  }

  .save-cancel-modal__text{
    font-size: rem-calc(12px);

    .highlight{
      font-family: $body-font-family-bold;
      color: $primary;
    }
  }

  .save-cancel-modal__form{
    textarea{
      color: $dark-gray;
      font-size: rem-calc(12px);
      min-height: 100px;
    }
  }

  .save-cancel-btn-container{
    text-align: center;
  }

  .save-cancel-btn{
    width: 100px;
  }

}
