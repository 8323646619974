.progressbar{

  margin-bottom: rem-calc(20px);

  .progressbar-table{
    width: 100%;
  }

  .progressbar-cell{
    width:6%;
    white-space:nowrap;
    text-align: center;
  }

  .progressbar-title{
    font-family: $body-font-family-medium;
    font-size: rem-calc(14px);
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: rem-calc(0 10px);
    color: $light-gray;

    &.active{
      color: $primary;
    }
  }

  .progressbar-spacer{
    border-bottom: 1px solid $light-gray;

    &.active{
      border-color: $primary;
    }
  }
}
