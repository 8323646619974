.login{
  margin-top: rem-calc(80px);
  background-color: $white;

  @include breakpoint(small down){
    margin-top: rem-calc(20px);
  }

  .login-left, .login-right{
    padding: rem-calc(50px);

    @include breakpoint(small down){
      padding: rem-calc(10px);
    }
  }

  .login-left{
    border-right: 1px solid $medium-gray;

    @include breakpoint(small down){
      border: none;
    }
  }

  .login-right{
    .login-right__inner{
      text-align: center;
      font-size: rem-calc(14px);
      color: $dark-gray;
      padding: rem-calc(0);

      @include breakpoint(small down){
        padding: rem-calc(0 10px);
      }
    }

    p{
      font-family: $body-font-family-medium;
    }

    a{
      color: $primary;
    }
  }

  .login-form{
    font-size: 14px;
    color: $dark-gray;

    .login-form__label{
      font-family: $body-font-family-medium;
      color: $dark-gray;
      font-size: rem-calc(12px);
    }
  }

  .box-gray__content{
    .--text, .--link{
      padding: 0;
    }
    .--text{
      font-weight: normal;
    }
    .--link{
      color: $dark-gray;
      font-weight: bold;
      text-decoration: underline;
    }
  }

}
