.select-period{

  .select-period__row-top{
    margin-bottom: 20px;
  }

  .select-period__col{
    @include breakpoint(small down){
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .select-period__button{
    background-color: $light-gray;
    display: block;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: $secondary;

    &:hover{
      background-color: $primary;
      color: $white;
    }
  }

}
