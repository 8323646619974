.filter-list-container{

  .filter-list{
    font-family: $body-font-family-medium;
    font-size: rem-calc(10px);
    color: $gray;
    text-transform: uppercase;
    list-style: none;
    text-align: right;
    margin: 0;
    margin-bottom: rem-calc(20px);

    @include breakpoint(small down){
      text-align: center;
    }
  }

  .filter-item{
    display: inline-block;
  }

  .filter-link{
    color: $gray;

    &.active, &:hover{
      color: $primary;
    }

  }

  .filter-link__space{
    padding: rem-calc(0 10px);
  }

}
