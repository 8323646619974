.main-container{
  background: url('/dist/assets/img/jpg/BG_dakota.jpg')no-repeat center center fixed;
  @include prefix(background-size, cover, webkit moz ms o);
  min-height: 100%;
}

.container{
  max-width:960px;
  margin: 0 auto;
}

.box{
  background-color: $white;
  margin-top: 80px;
  padding: 50px 60px;

  @include breakpoint(small down){
    margin-top: 20px;
    padding: 10px;
  }
}

.box-gray{
  background-color: $light-gray;
  padding: 30px 10px;
}
