.header{
  .container{
    padding: rem-calc(23px 0);
  }

  .header-logo-container{

    .header-logo{
      background: url('/dist/assets/img/svg/logo_dakota.svg') no-repeat;
      width: 100%;
      height: 30px;
    }

    @include breakpoint(small down){
      text-align: center;
    }
  }

  .header-list-container{
    text-align: right;

    @include breakpoint(small down){
      text-align: center;
    }
  }

  .header-list{
    list-style: none;
    margin: 0;
    display: inline-block;
  }

  .header-list__item{
    display: inline-block;
    padding: rem-calc(6px 8px);
  }

  .header-link{
    text-decoration: none;
    color: $secondary;
    font-weight: 300;
    font-family: $body-font-family;
  	font-size: rem-calc(18px);

    &:link, &:visited {
      color: $secondary;
    }
     &:hover {
      color: $primary;
    }
  }

  .header-link__text{
    padding-bottom: rem-calc(8px);

    &.active{
      border-bottom: 3px solid $secondary;
    }
  }
}
