.table{
  display: table;
}

.vertical-align{
  display: table-cell;
  vertical-align: middle;
}

.no-margin{
  margin: 0;
}
