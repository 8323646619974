.select-finish{

  .select-finish__container{
    text-align: center;
    font-size: rem-calc(12px);
    color: $dark-gray;
  }

  .select-finish__icon.tick{
    margin-bottom: 20px;
  }

  .select-finish__text.name{
    color: $primary;
  }
}
