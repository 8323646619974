.dashboard{
  .username{
    color: $primary;
  }

  .dashboard__image{
    margin-bottom: rem-calc(10px);
  }

  .dashboard__link{
    display:block;
    text-align: center;
    color: $black;

    &:hover{
      color: $primary;
    }
  }

  .dashboard__text{
    font-family: $body-font-family-medium;
    font-size: rem-calc(14px);
    text-transform: uppercase;
    margin: 0;
  }
}
